<template>
  <div class="data-manage flexible">
    <div class="main-content" :class="showSearchDrawer ? 'active' : ''">
      <div class="page-title clearfix">
        <div class="float-left">
          <span>{{ $t("nav.PET_analysis") }}</span>
          <span class="total-num">{{ totalCount }}</span>
        </div>
      </div>
      <div class="data-content">
        <div class="search-wrapper clearfix">
          <form class="form-inline float-right">
            <button type="submit" class="btn btn-dark btn-sm">
              {{ $t("search") }}
            </button>
            <div class="form-group">
              <input
                type="search"
                name="search"
                class="form-control"
                placeholder
              />
            </div>
            <!-- <span class="advanced-btn ml10" @click="showSearchWrapper">{{$t('a_search')}}</span> -->
          </form>
        </div>
        <div class="tabel-content">
          <el-table
            ref="multipleTable"
            :data="dataList"
            :empty-text="$t('no_data')"
            stripe
            style="width: 100%"
            header-row-class-name="thead-dark"
          >
            <el-table-column
              prop="subject_number"
              :label="$t('_data.p_name')"
              width="180"
            >
              <template v-slot="scope">
                <router-link
                  :to="{ name: 'subjectDetail', query: { id: scope.row.id } }"
                  >{{ scope.row.subject_number }}</router-link
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="subject_gender"
              :formatter="formatGender"
              :label="$t('gender')"
              width="70"
            ></el-table-column>
            <el-table-column
              prop="subject_age"
              :label="$t('age')"
              width="60"
            ></el-table-column>
            <el-table-column
              prop="date_created"
              :label="$t('s_time')"
            ></el-table-column>
            <el-table-column
              prop="date_update"
              :label="$t('u_time')"
            ></el-table-column>
            <el-table-column
              prop="type_name"
              :formatter="formatType"
              :label="$t('analyse.type')"
            ></el-table-column>
            <el-table-column
              prop="address"
              :label="$t('analyse.data_owner')"
            ></el-table-column>
            <el-table-column
              prop="address"
              :label="$t('analyse.analyse_name')"
            ></el-table-column>
            <el-table-column prop="status" :label="$t('analyse.status')">
              <template v-slot="scope">
                <span :class="statusColClass(scope.row.status)">{{
                  scope.row.status | formatStatus
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="status" :label="$t('analyse.result')">
              <template v-slot="scope">
               
               <a v-if="scope.row.status=='2' " :href="'http://pet.viewer.brainlabel.org/#/?type='+scope.row.type_name+'keyid='+scope.row.id+'&cid=1&tid='+scope.row.task_id+'&requrl=/petview/index/&host=http://cafe.brainlabel.org/api/&token='" target="_blank">{{$t('analyse.pet_viewer')}}</a>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              :label="$t('operate')"
              align="right"
            >
              <template v-slot="scope">
                <div class="dropdown d-inline-block dropleft">
                  <div class="cursor" @click.stop="showMenu(scope.row)">
                    <i class="fa fa-bars mr-1"></i>
                    <i class="fa fa-angle-down"></i>
                  </div>
                  <div
                    class="dropdown-menu shadow-lg"
                    :class="{ show: scope.row.id === rowId }"
                  >
                    <div class="dropdown-header">{{ $t("operate_menu") }}</div>
                    <!-- <div
                      class="dropdown-item cursor"
                      @click.stop="modifyInfo(scope.row)"
                    >
                      <i class="fa fa-edit"></i> {{ $t("analyse.resubmit") }}
                    </div> -->
                    <div class="dropdown-divider"></div>
                    <div
                      class="dropdown-item cursor text-danger"
                      @click="deleteSubject(scope.row)"
                    >
                      {{ $t("delete") }}
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="clearfix mt-3 mb-3">
        <div class="float-left">
          <!-- <pagination
            v-if="totalCount > pageSize"
            :pageSize="pageSize"
            :layout="layout"
            :currentPage="currentPage"
            :total="totalCount"
            @goToPage="handleCurrentChange"
          /> -->
        </div>
        <!-- <div class="float-right">
          <button type="button" class="btn btn-primary btn-sm" @click.stop="batchDownloadData">
            <i class="fa fa-download mr5"></i>
            {{$t('_data.batch_download')}}
          </button>
          <button type="button" class="btn btn-info btn-sm ml-3" @click.stop="showAddPopover = true">
            <i class="fa fa-people-carry mr5"></i>
            {{$t('_data.add_to_pro')}}
          </button>
        </div> -->
      </div>
    </div>
    <div class="empty-wrapper" :class="showSearchDrawer ? 'active' : ''"></div>
    <div class="search-drawer" :class="showSearchDrawer ? 'active' : ''">
      <p class="top-line clearfix">
        <span>高级搜索</span>
        <span class="float-right close-search" @click="hideSearchWrapper"
          >关闭</span
        >
      </p>
      <div class="drawer-body">
        <div class="block">
          <span class="demonstration">上传时间</span>
          <el-date-picker
            v-model="value1"
            type="daterange"
            size="mini"
            :clearable="clearable"
            prefix-icon=" "
            range-separator="—"
            start-placeholder
            end-placeholder
          ></el-date-picker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { analyseRequest } from "../../api/api";
// import pagination from "../../components/pager";
// import { paginationMixin } from "../../utils/mixin";

let that;

export default {
  // mixins: [paginationMixin],
  // components: {
  //   pagination
  // },
  data() {
    return {
      dataList: new Array(),
      showSearchDrawer: false,
      totalCount: 0,
      value1: "",
      clearable: false,
      rowId: "",
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : {}
    };
  },
  methods: {
    showSearchWrapper() {
      this.showSearchDrawer = true;
    },
    hideSearchWrapper() {
      this.showSearchDrawer = false;
    },
    async getAnalyseList() {
      let params = {
        request: "getpetlist",
        userid: this.$store.state.userid,
        search: " "
      };
      try {
        let res = await analyseRequest(params);
        if (res.status === 200) {
          this.dataList = res.data.length > 0 ? res.data : [];
          this.totalCount = res.total;
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getAnalyseList();
    },
    formatGender: function (row) {
      return row.subject_gender === "1"
        ? this.$t('male')
        : row.subject_gender === "0"
          ? this.$t('female')
          : "";
    },
    formatType: function (row) {
      switch (row.type_name) {
        case "PT":
          return "AV133";
        case "PTMR":
          return "PET MRI";
        case "PTCT":
          return "PET CT";
        default:
          return "";
      }
    },
    statusColClass: function (status) {
      switch (status) {
        case "1":
          return "text-muted";
        case "2":
          return "text-primary";
        case "3":
          return "text-success";
        case "4":
          return "text-danger";
        default:
          return "";
      }
    },
    showMenu(rowData) {
      this.rowId = rowData.id;
    },
    hideMenu() {
      this.rowId = "";
    },
    async deleteSubject(subject) {
      this.rowId = "";
      let params = {
        request: "remove",
        userid: this.user.id,
        subject_id: subject.id,
        projectid: subject.project_id || 0,
        groupid: subject.group_id || 0
      };
      let _self = this;
      try {
        let res = await analyseRequest(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message, function(){
            _self.getAnalyseList();
          });
        } else {
          console.log(res);
          this.$helper.errNotify(this.$t("del_failed"));
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    }
  },
  mounted() {
    this.getAnalyseList();
    window.addEventListener("click", this.hideMenu, false);
  },
  beforeCreate() {
    that = this;
  },
  filters: {
    formatStatus: function (status) {
      switch (status) {
        case "0":
          return that.$t("task_status.wait");
        case "1":
          return that.$t("task_status.Processing");
        case "2":
          return that.$t("task_status.completed");
        case "3":
          return that.$t("task_status.failed");
        default:
          return "";
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("click", this.hideMenu);
  }
};
</script>

<style lang="less">
.table {
  border-bottom: 1px solid #dee2e6;
}
.data-manage {
  .main-content {
    transition: 0.3s ease-in;
  }
  .main-content.active {
    width: calc(~"100% - 220px");
    // transform: translate(-220px);
    // margin-right: 220px;
  }
  .el-checkbox {
    margin-bottom: 0;
    // color: #fff;
  }
  .el-table .cell {
    overflow: visible;
  }
  .el-table {
    overflow: visible;
  }
  .el-table__body-wrapper {
    overflow: visible !important;
  }
}
.data-manager-popover {
  .popover-content {
    margin: 120px auto 0;
    width: 700px;
    background: #fff;
  }
}
.dialog-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2003;
  overflow: auto;
  margin: 0;
  background: rgba(0, 0, 0, 0.6);
  .dialog-content {
    position: relative;
    margin: 15vh auto;
    border-radius: 3px;
    width: 600px;
    background: #fff;
  }
  .dialog-header {
    padding: 20px 10px;
    border-bottom: 1px solid #dee2e6;
  }
  .dialog-title {
    line-height: 24px;
    font-size: 18px;
    color: #303133;
  }
  .dialog-headerbtn {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
  }
  .dialog-body {
    padding: 30px 0;
    text-align: center;
  }
}
</style>
